import 'core-js/es/object'
import React from 'react'
import 'react-app-polyfill/ie11'
import * as ReactDOM from 'react-dom'
import { LocationProvider } from '@reach/router'

import { Auth0Wrapper } from './modules/components/Auth0Wrapper'
import { UnsupportedBrowserPage } from './modules/pages/UnsupportedBrowserPage'
import { mixpanelSetup } from './utils/mixpanelWrapper'
import { detectUnsupportedBrowser } from './utils/detectUnsupportedBrowser'

const AppointmentApp = React.lazy(() => import('./AppointmentApp').then(module => ({ default: module.AppointmentApp })))

const unsupportedBrowser = detectUnsupportedBrowser()

mixpanelSetup().then(() =>
  ReactDOM.render(
    <>
      {unsupportedBrowser ? (
        <>
          <UnsupportedBrowserPage />
        </>
      ) : (
        <React.Suspense fallback={<div></div>}>
          <LocationProvider>
            <Auth0Wrapper>
              <AppointmentApp />
            </Auth0Wrapper>
          </LocationProvider>
        </React.Suspense>
      )}
    </>,
    document.getElementById('root') as HTMLElement
  )
)
