import { ActionButton, AppointmentOriginEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { navigate } from '@reach/router'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'

import { UIState } from '../../../../src/styles'
import { notificationService, useBookingStore } from '../../../injectables'
import { deleteEventByBookingId } from '../../../requests'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { getShortBookingId } from '../../../utils/bookingId'
import { AppointmentSummary } from '../../components/AppointmentSummary'
import { PageTitle } from '../../components/PageTitle'
import { currentPage, currentWorkflowPage, getWorkflow, getWorkflowTeam } from '../../../utils/mixpanel'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const useStyles = makeStyles({
  buttonGrid: {
    marginTop: '32px'
  },
  bookingIdContainer: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '0.25rem'
  },
  boldLabel: {
    fontWeight: 600,
    marginTop: '16px'
  },
  label: {
    marginTop: '16px'
  },
  title: {
    marginTop: '70px'
  }
})

interface CancelConfirmProps {
  onSuccess: React.Dispatch<React.SetStateAction<void>>
}

export const BaseCancelConfirmComponent = ({ onSuccess }: CancelConfirmProps) => {
  const classes = useStyles()
  const {
    eventId,
    bookingId,
    email,
    branchName,
    branchAddress,
    summaryServiceType,
    shortDateString,
    appointmentTimeRange,
    advisor,
    meetingMethod,
    origin,
    province,
    existingCustomer,
    employerName
  } = useBookingStore()

  const onClickEdit = (event: React.MouseEvent) => {
    event.preventDefault()
    mixpanelTrack(MixpanelEvents.CancelPageClickModify, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    navigate(`/modify?bookingId=${getShortBookingId(bookingId)}`)
  }

  const handleCancel = (_values: unknown, actions: FormikHelpers<Record<string, never>>): void => {
    deleteEventByBookingId(eventId!, bookingId!, email!, true)
      .then(() => {
        actions.setSubmitting(false)
        mixpanelTrack(MixpanelEvents.CancelAppointment, {
          cancelSuccess: 'Yes',
          page: currentPage(),
          workflowPage: currentWorkflowPage(),
          workflow: getWorkflow(origin),
          workflowTeam: getWorkflowTeam(origin)
        })
        onSuccess()
      })
      .catch(() => {
        actions.setSubmitting(false)
        mixpanelTrack(MixpanelEvents.CancelAppointment, {
          cancelSuccess: 'No',
          page: currentPage(),
          workflowPage: currentWorkflowPage(),
          workflow: getWorkflow(origin),
          workflowTeam: getWorkflowTeam(origin)
        })
        notificationService.createNotification('Something went wrong', UIState.Error)
      })
  }

  return (
    <Formik initialValues={{}} onSubmit={handleCancel}>
      {({ isSubmitting }) => (
        <Form data-testid='cancel-confirm-form'>
          <Grid item className={classes.title}>
            <PageTitle littleTitle={i18n.__(copy.Cancel.LittleTitle)} bigTitle={i18n.__(copy.Cancel.BigTitle)} />
          </Grid>
          <Grid item>
            <AppointmentSummary
              branchName={branchName}
              branchAddress={branchAddress}
              serviceSummary={summaryServiceType}
              date={shortDateString}
              timeRange={appointmentTimeRange}
              advisor={advisor}
              editLabelText='Modify booking'
              onClickEdit={onClickEdit}
              meetingMethod={meetingMethod}
              bookingId={bookingId}
              origin={origin}
              province={province}
              existingGWS={existingCustomer && origin === AppointmentOriginEnum.GWS}
              employerName={employerName}
            />
          </Grid>
          <Grid item className={classes.buttonGrid}>
            <ActionButton
              buttonText={i18n.__(copy.Buttons.Cancel)}
              submitting={isSubmitting}
              disabled={isSubmitting}
              type='submit'
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

BaseCancelConfirmComponent.defaultProps = {
  onSuccess: () => {
    // noop
  }
}

export const CancelConfirmComponent = observer(BaseCancelConfirmComponent)
