import { makeStyles } from '@material-ui/core/styles'
import Theme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FeedbackIcon from '@material-ui/icons/Feedback'
import React, { useState } from 'react'
import { useBookingStore } from '../../../injectables'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { currentPage, getWorkflow, getWorkflowTeam, currentWorkflowPage } from '../../../utils/mixpanel'
import { FeedbackModal } from './FeedbackModal'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '45.5px'
  },
  containerMobile: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  feedbackIcon: {
    color: '#0072F0',
    fontSize: '20',
    marginLeft: '10px',
    marginRight: '10px'
  },
  feedbackText: {
    color: '#0072F0',
    fontWeight: 'bold'
  },
  button: {
    padding: 0,
    minWidth: 0,
    '&:hover': {
      background: 'none'
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color solid 1px',
      outlineOffset: '3px'
    }
  }
})

export const Feedback = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const classes = useStyles()
  const theme = Theme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { origin } = useBookingStore()

  const handleFeedbackClick = () => {
    mixpanelTrack(MixpanelEvents.SendFeedback, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage()
    })
    setModalOpen(true)
  }

  const FeedbackButtonContent = () => (
    <>
      <FeedbackIcon className={classes.feedbackIcon} data-testid='feedback-icon' />
      {!mobile && (
        <Typography variant='body2' className={classes.feedbackText} data-testid='feedback-text'>
          Send us feedback
        </Typography>
      )}
    </>
  )

  return (
    <div className={mobile ? classes.containerMobile : classes.container}>
      <Button
        data-testid='feedback-button'
        className={classes.button}
        onClick={() => handleFeedbackClick()}
        disableRipple
        aria-label='send-feedback'
      >
        <FeedbackButtonContent />
      </Button>
      <FeedbackModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  )
}
