import { navigate } from '@reach/router'
import { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'

import { useFeatureStore } from '../stores'
import { useAuth0Client } from './useAuth0Client'
import { AuthClientID, usePingAuth } from './usePingAuth'
import { AuthSource } from '../../types'
import { setMixpanelUser } from '../../utils/mixpanelWrapper'
export const AUTH_SOURCE_STORAGE = 'auth_source'
const CUSTOM_CLAIMS = 'https://api.atb.com/custom_claims'
const CLIENT_ID = 'client_id'
const EXTERNAL_UUID = 'externalUUID'
export interface AuthenticationContext {
  authError?: string
  authToken?: string
  getBpid: () => string | undefined
  isAuthenticated: () => boolean
  isBusinessClient: () => boolean
  isGuest: () => boolean
  isRetailClient: () => boolean
  logout: (url?: string) => void
  redirectToBusinessLogin: () => void
  redirectToPersonalLogin: () => void
  reset: () => void
}

export interface DecodedToken {
  [CLIENT_ID]: string
  [EXTERNAL_UUID]: string
  cus: string
  [CUSTOM_CLAIMS]: {
    connectionName: string
    cus: string
    [EXTERNAL_UUID]: string
  }
}

export const useAuthentication = (branchId?: string): AuthenticationContext => {
  const [authError, setAuthError] = useState<string>()
  const [authSource, setAuthSource] = useState<AuthSource | undefined>(
    sessionStorage.getItem(AUTH_SOURCE_STORAGE) as AuthSource
  )
  const [authToken, setAuthToken] = useState<string>()
  const [decodedToken, setDecodedToken] = useState<DecodedToken>()

  const { usePingBusinessLoginFlow } = useFeatureStore()
  const { logout: auth0Logout, loginRedirect: auth0Login, token: auth0Token } = useAuth0Client(branchId)
  const {
    authListener: pingAuthListener,
    authRequest: pingAuthLogin,
    completeAuthorizationRequest,
    error,
    logout: pingLogout,
    token: pingToken
  } = usePingAuth(branchId)

  const reset = () => {
    setAuthError(undefined)
    setAuthSource(undefined)
    setAuthToken(undefined)
    setDecodedToken(undefined)
    sessionStorage.removeItem(AUTH_SOURCE_STORAGE)
  }

  useEffect(() => {
    pingAuthListener(authSource)

    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    const errorDescription = urlParams.get('error_description') || undefined

    if (errorDescription) {
      if (authSource === AuthSource.retail || usePingBusinessLoginFlow) {
        setAuthError(errorDescription)
      } else {
        auth0Login(errorDescription)
      }
    } else {
      completeAuthorizationRequest()
    }
  }, [])

  useEffect(() => {
    if (error) {
      setAuthError(error)
    }
  }, [error])

  useEffect(() => {
    if (authSource) {
      sessionStorage.setItem(AUTH_SOURCE_STORAGE, authSource)
    } else {
      sessionStorage.removeItem(AUTH_SOURCE_STORAGE)
    }
  }, [authSource])

  useEffect(() => {
    if (pingToken) {
      setAuthToken(pingToken)
      setDecodedToken(jwt_decode(pingToken))
      setMixpanelUser(jwt_decode(pingToken))
    }
  }, [pingToken])

  useEffect(() => {
    if (auth0Token) {
      setAuthToken(auth0Token)
      setDecodedToken(jwt_decode(auth0Token))
      setMixpanelUser(jwt_decode(auth0Token))
    }
  }, [auth0Token])

  const redirectToPingLogin = (source: AuthSource) => {
    setAuthSource(source)
    pingAuthLogin(source)
  }

  const redirectToPersonalLogin = () => {
    redirectToPingLogin(AuthSource.retail)
  }

  const redirectToBusinessLogin = () => {
    if (usePingBusinessLoginFlow) {
      redirectToPingLogin(AuthSource.business)
    } else {
      setAuthSource(AuthSource.business)
      auth0Login()
    }
  }

  const logout = (url = 'https://atb.com') => {
    setAuthError(undefined)

    if (authSource === AuthSource.retail || usePingBusinessLoginFlow) {
      pingLogout(url)
    } else if (authSource === AuthSource.business) {
      auth0Logout(url)
    } else {
      navigate(url)
    }

    sessionStorage.removeItem(AUTH_SOURCE_STORAGE)
  }

  const getBpid = () => {
    if (decodedToken) {
      return (decodedToken['cus'] || decodedToken[CUSTOM_CLAIMS].cus).padStart(10, '0')
    }

    return
  }

  const isAuthenticated = () => !!authToken && !!decodedToken

  const isBusinessClient = () =>
    !!decodedToken &&
    (decodedToken[CLIENT_ID] === AuthClientID.business ||
      decodedToken[CUSTOM_CLAIMS].connectionName === 'BusinessBanking')

  const isRetailClient = () =>
    !!decodedToken &&
    (decodedToken[CLIENT_ID] === AuthClientID.retail || decodedToken[CUSTOM_CLAIMS].connectionName === 'RetailPing')

  const isGuest = () => !authSource

  return {
    authError,
    authToken,
    getBpid,
    isAuthenticated,
    isBusinessClient,
    isGuest,
    isRetailClient,
    logout,
    redirectToBusinessLogin,
    redirectToPersonalLogin,
    reset
  }
}
