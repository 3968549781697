import {
  ActionButton,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  AppointmentTypeField,
  Branch,
  DialogueDisplay,
  MeetingMethodEnum,
  MeetingMethodField,
  LoanAmountEnum,
  LoanAmountField,
  ServiceTypeSelectField,
  TextInputField,
  i18n,
  copy,
  Options
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import axios, { Canceler } from 'axios'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'

import Button from '@material-ui/core/Button'
import { useAuthentication, useBookingStore, useFeatureStore } from '../../../injectables'
import { getBranchList, getCustomerInfo } from '../../../requests'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { FORM_WIDTH } from '../../../utils/constants'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'
import { findBranch } from '../../../utils/findBranch'
import { formatPhoneNumber } from '../../../utils/format'
import { StatusNotification } from '../StatusNotification'
import { PageTitle } from '../PageTitle'
import { DetailsFormValues } from '../../../types'
import { currentPage } from '../../../utils/mixpanel'
import { Modal } from '../Modal/Modal'
import { expiredModalStyle } from '../Modal/ModalStyles'
import { Logout } from '../Logout'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

export interface DetailsFormProps {
  branchId: string
  bigTitle: string
  littleTitle: string
  submitButtonText: string
  isModify?: boolean
  handleSubmit: (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => void
}

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: FORM_WIDTH,
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  statusNotificationContainer: {
    marginBottom: '32px',
    paddingRight: '16px',
    paddingLeft: '16px',
    width: '100%'
  },
  textBox: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    borderRadius: 4,
    backgroundColor: 'rgb(0, 114, 240)',
    marginLeft: '8px',
    marginRight: '8px',
    padding: '8px'
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#63666b',
    margin: 8
  },
  afterInfoText: {
    fontSize: '14px',
    paddingTop: '8px'
  },
  labelText: {
    fontSize: '14px',
    marginBottom: '8px'
  },
  inlineLink: {
    textDecoration: 'underline'
  },
  learnMoreButton: {
    padding: 0,
    border: 'solid 2px transparent',
    '&:focus-visible,&:hover': {
      border: 'solid 2px #000025',
      backgroundColor: 'transparent'
    }
  },
  learnMoreButtonText: {
    fontSize: '14px',
    color: '#0072F0',
    fontWeight: 700,
    padding: '14px 14px',
    lineHeight: '24px'
  },
  buttonRow: {
    marginTop: '32px'
  },
  continueButton: {
    width: '119px',
    marginLeft: 'auto'
  }
})

export const BaseDetailsForm = ({
  branchId,
  bigTitle,
  littleTitle,
  submitButtonText,
  handleSubmit
}: DetailsFormProps) => {
  const cancelCustomerInfoRequest = useRef<Canceler>()

  const classes = useStyles()

  const {
    appointmentType,
    branch,
    description,
    bpId,
    serviceSubType,
    loanAmount,
    meetingMethod,
    setAppointmentType,
    setBranch,
    setDescription,
    setMeetingMethod,
    setServiceSubType,
    setLoginType,
    setLoanAmount,
    preFillFields
  } = useBookingStore()

  const { setSkipFindALocation, branchAlertNotification } = useFeatureStore()
  const { authError, authToken, getBpid, isAuthenticated, isBusinessClient, isGuest, isRetailClient, logout } =
    useAuthentication(branchId)

  const { required: requiredText } = copy.Validation

  const handleLearnMoreButtonClick = () => {
    mixpanelTrack(MixpanelEvents.SuggestDigitalOptions, {
      appointmentSubtype: serviceSubType,
      clientType: appointmentType,
      page: currentPage()
    })
  }

  useEffect(() => {
    let branchInURL = ''
    const urlPattern = new RegExp('/book/[0-9]{5}')
    const urlMatch = window.location.pathname.match(urlPattern)
    if (urlMatch) {
      branchInURL = urlMatch[0].substring(6)
    }
    // branchInURL gets populated when user logs in to Auth0
    // branchId is populated if user uses guest flow
    if (!branch && (branchInURL.length === 5 || branchId.length === 5)) {
      getBranchList().then(results => {
        const matchedBranch = findBranch(results, branchInURL || branchId)
        if (matchedBranch) {
          setBranch(matchedBranch as Branch)
          setSkipFindALocation(true)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    const LANDING_SCREEN_TYPE = 'OABT Details Page'
    let onBookingDetailsForm = true

    if (isGuest()) {
      mixpanelTrack(MixpanelEvents.LoginSuccess, {
        clientType: 'Guest',
        landingScreenType: LANDING_SCREEN_TYPE
      })
      return
    }

    if (!authToken || bpId) {
      return
    }

    const bpIdToken = getBpid()
    const commonMixpanelProperties = {
      landingScreenType: LANDING_SCREEN_TYPE,
      loginMethod: 'Typed Username Password'
    }

    if (isRetailClient()) {
      setAppointmentType(AppointmentTypeEnum.PERSONAL)
      setLoginType(AppointmentTypeEnum.PERSONAL)
      const cancelToken = new axios.CancelToken(c => {
        cancelCustomerInfoRequest.current = c
      })
      getCustomerInfo(authToken, cancelToken).then(customerInfo => {
        const { customerName, phoneNumber, email } = customerInfo
        if (onBookingDetailsForm) {
          preFillFields({
            firstName: customerName?.firstName,
            lastName: customerName?.lastName,
            email,
            phoneNumber: formatPhoneNumber(phoneNumber),
            existingCustomer: bpIdToken ? true : undefined,
            bpId: bpIdToken
          })
        }
      })

      mixpanelTrack(MixpanelEvents.LoginSuccess, {
        ...commonMixpanelProperties,
        clientType: 'Personal banking'
      })
    } else if (isBusinessClient()) {
      setAppointmentType(AppointmentTypeEnum.BUSINESS)
      setLoginType(AppointmentTypeEnum.BUSINESS)
      preFillFields({
        existingCustomer: bpIdToken ? true : undefined,
        bpId: bpIdToken
      })

      mixpanelTrack(MixpanelEvents.LoginSuccess, {
        ...commonMixpanelProperties,
        clientType: 'Business banking'
      })
    }

    return function cleanup() {
      if (cancelCustomerInfoRequest.current) {
        cancelCustomerInfoRequest.current()
      }
      onBookingDetailsForm = false
    }
  }, [authToken]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    logout(`${window.location.origin}${window.location.pathname}`)
  }

  return (
    <Formik
      initialValues={
        {
          appointmentType,
          branch: branch?.name ? branch : null,
          description: description || '',
          loanAmount,
          serviceSubType,
          meetingMethod
        } as DetailsFormValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid
            data-testid='booking-details-form'
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Modal
              open={!!authError}
              modalStyle={expiredModalStyle}
              modalTitle={i18n.__(copy.Auth.ErrorModal.Title)}
              modalBody={i18n.__(copy.Auth.ErrorModal.Text)}
              modalFooter={
                <ActionButton
                  style={{ width: '200px' }}
                  buttonText={i18n.__(copy.Buttons.LoginGuest)}
                  type='button'
                  data-testid='expired-modal-btn'
                  onClick={handleCloseModal}
                />
              }
              onClose={handleCloseModal}
            />
            {branchAlertNotification.show && (
              <Grid item className={classes.statusNotificationContainer}>
                <StatusNotification title={branchAlertNotification.title} message={branchAlertNotification.message} />
              </Grid>
            )}
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle littleTitle={littleTitle} bigTitle={bigTitle} step='Step 1 of 4' />
              </Grid>
              <Grid item className={classes.formItem}>
                <AppointmentTypeField
                  name='appointmentType'
                  onClickOption={(value: string) => {
                    if (appointmentType !== value) {
                      setAppointmentType(value as AppointmentTypeEnum)
                      setServiceSubType(undefined)
                    }
                  }}
                  validate={value => required(value, i18n.__(requiredText.appointmentType))}
                  validateOnBlur
                />
              </Grid>
              {values.appointmentType && (
                <>
                  <Grid
                    item
                    className={
                      values.serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT
                        ? classes.formItemNoMargin
                        : classes.formItem
                    }
                  >
                    <Typography
                      className={classes.labelText}
                      variant='body1'
                      data-testid='service-subtype-select-header'
                    >
                      What can we help you with?
                    </Typography>
                    <ServiceTypeSelectField
                      name='serviceSubType'
                      options={Options.ServiceSubType[values.appointmentType!]}
                      label='Select service type'
                      onChange={(event: React.ChangeEvent<{ value: AppointmentSubTypeEnum }>) => {
                        setServiceSubType(event.target.value)
                      }}
                      validate={value => required(value, i18n.__(requiredText.serviceSubType))}
                      validateOnBlur
                    />
                  </Grid>
                  {values.serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT && (
                    <Grid item className={classes.formItem}>
                      <LoanAmountField
                        name='loanAmount'
                        onClickOption={(value: string) => {
                          setLoanAmount(value as LoanAmountEnum)
                        }}
                        validate={value => required(value, i18n.__(requiredText.loanAmount))}
                        validateOnBlur
                      />
                    </Grid>
                  )}
                  {values.serviceSubType &&
                    copy.DigitalOptions[values.serviceSubType] &&
                    copy.DigitalOptions[values.serviceSubType][values.appointmentType] && (
                      <Grid item className={classes.formItem} data-testid='digital-options-info-box'>
                        <DialogueDisplay
                          id='digital-options'
                          displayMessage={i18n.__(
                            copy.DigitalOptions[values.serviceSubType][values.appointmentType].Message
                          )}
                          dialogueFooter={
                            <Button
                              className={classes.learnMoreButton}
                              data-testid='learn-more-button'
                              href={i18n.__(copy.DigitalOptions[values.serviceSubType][values.appointmentType].Link)}
                              target='_blank'
                              onClick={handleLearnMoreButtonClick}
                              disableRipple
                            >
                              <div className={classes.learnMoreButtonText}>
                                {i18n.__(copy.DigitalOptions[values.serviceSubType][values.appointmentType].ButtonText)}
                              </div>
                            </Button>
                          }
                        />
                      </Grid>
                    )}
                </>
              )}
              <Grid item className={classes.formItem}>
                <MeetingMethodField
                  name='meetingMethod'
                  onClickOption={(value: string) => {
                    setMeetingMethod(value as MeetingMethodEnum)
                  }}
                  infoMessage={
                    !bpId &&
                    meetingMethod &&
                    [MeetingMethodEnum.ONLINE, MeetingMethodEnum.PHONE].includes(meetingMethod)
                      ? i18n.__(copy.MeetingMethod.NewClientInBranchRequired)
                      : ''
                  }
                  validate={value => required(value, i18n.__(copy.Validation.required.meetingMethod))}
                  validateOnBlur
                />
              </Grid>
              <>
                <Grid item className={classes.formItem}>
                  <TextInputField
                    name='description'
                    label={i18n.__(copy.Book.DescriptionInput.Label)}
                    placeHolderText={i18n.__(copy.Book.DescriptionInput.PlaceHolderText)}
                    onChange={(value: string) => {
                      setDescription(value)
                    }}
                    validate={value => required(value, i18n.__(requiredText.description)) || sensitiveInfo(value)}
                    validateOnBlur
                    ariaRequired
                  />
                </Grid>
              </>

              <Grid item className={classes.formItem}>
                <Grid container direction='row' className={classes.buttonRow}>
                  <Grid item>
                    <Logout
                      testid='cancel-button'
                      loggedIn={isAuthenticated()}
                      text={i18n.__(copy.Buttons.CancelShort)}
                      onClick={() => logout()}
                    />
                  </Grid>
                  <Grid item className={classes.continueButton}>
                    <ActionButton
                      buttonText={submitButtonText}
                      submitting={isSubmitting}
                      disabled={
                        isSubmitting ||
                        (values.serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT && !loanAmount) ||
                        !serviceSubType ||
                        !description ||
                        !meetingMethod
                      }
                      type='submit'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const DetailsForm = observer(BaseDetailsForm)
