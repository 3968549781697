import { ActionButton, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import { bookingStore, fetchEventByBookingId, useBookingStore } from '../../../injectables'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { currentPage, getWorkflow, getWorkflowTeam } from '../../../utils/mixpanel'
import { BookingInformation } from '../../components/BookingInformation'
import { PageTitle } from '../../components/PageTitle'
import { BookingInfoValues } from '../../../types'
import { emailFormat, required } from '../../../utils/fieldValidation'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const useStyles = makeStyles({
  inputGrid: {
    marginTop: '16px'
  },
  buttonGrid: {
    marginTop: '32px'
  },
  validationError: {
    marginTop: '16px',
    paddingLeft: '4px',
    color: '#EB0042'
  },
  captionGrid: {
    paddingLeft: '4px'
  }
})

interface BaseBookingInfoProps {
  onSuccess: () => void
  onFailure?: () => void
}

export const BaseBookingInfo = ({ onSuccess, onFailure }: BaseBookingInfoProps) => {
  const classes = useStyles()
  const [validationError, setValidationError] = useState(false)

  const { bookingId, email, setBookingId, setEmail } = useBookingStore()
  const title = i18n.__(copy.BookingInfo.AccessBookingTitle)
  const searchParams = new URLSearchParams(window.location.search)
  const bookingIdParam = searchParams.get('bookingId') || undefined

  const handleContinue = (values: BookingInfoValues, actions: FormikHelpers<BookingInfoValues>) => {
    actions.setSubmitting(true)

    fetchEventByBookingId(bookingId!, email!)
      .then(() => {
        const origin = bookingStore.origin
        mixpanelTrack(MixpanelEvents.ValidateModifyOrCancel, {
          page: currentPage(),
          workflowPage: 'Validate',
          validateSuccess: 'Yes',
          workflow: getWorkflow(origin),
          workflowTeam: getWorkflowTeam(origin)
        })
        actions.setSubmitting(false)
        onSuccess()
      })
      .catch(error => {
        mixpanelTrack(MixpanelEvents.ValidateModifyOrCancel, {
          page: currentPage(),
          workflowPage: 'Validate',
          validateSuccess: 'No',
          workflow: 'not applicable',
          workflowTeam: 'not applicable'
        })
        if (onFailure && error?.response?.status === 410) {
          onFailure()
        } else {
          setValidationError(true)
          actions.setSubmitting(false)
        }
      })
  }

  useEffect(() => {
    setBookingId(bookingIdParam)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={
        {
          bookingId: bookingId || bookingIdParam || '',
          email: email || ''
        } as BookingInfoValues
      }
      onSubmit={handleContinue}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form data-testid='booking-info-form'>
          <Grid item>
            <PageTitle littleTitle='' bigTitle={title} />
          </Grid>
          <Grid item className={classes.captionGrid}>
            <Typography variant='caption'>{i18n.__(copy.BookingInfo.AccessBookingInfoCaption)}</Typography>
          </Grid>
          <Grid item className={classes.inputGrid}>
            <BookingInformation
              onChangeBookingId={(value: string) => setBookingId(value)}
              onChangeEmail={(value: string) => setEmail(value)}
              validate={{
                bookingId: value => required(value, i18n.__(copy.Validation.required.bookingId)),
                email: value => required(value, i18n.__(copy.Validation.required.email)) || emailFormat(value)
              }}
              validateOnBlur
            />
          </Grid>
          {validationError && (
            <Grid item className={classes.validationError} data-testid='booking-info-validation-error'>
              <Typography role='alert' variant='caption'>
                {i18n.__(copy.BookingInfo.AccessBookingInfoError)}
              </Typography>
            </Grid>
          )}
          <Grid item className={classes.buttonGrid}>
            <ActionButton
              buttonText={i18n.__(copy.Buttons.Continue)}
              submitting={isSubmitting}
              disabled={isSubmitting}
              type='submit'
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

BaseBookingInfo.defaultProps = {
  onSuccess: () => {
    // noop
  }
}

export const BookingInfo = observer(BaseBookingInfo)
