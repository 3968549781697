import { debounceTime, AppointmentOriginEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { navigate, RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Feedback } from '../../components/Feedback'

import {
  convertClientType,
  currentPage,
  secondsSinceTime,
  convertLoanAmount,
  currentWorkflowPage
} from '../../../../src/utils/mixpanel'
import { fetchAvailability, useBookingStore } from '../../../injectables'
import { routes } from '../../../routing'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { useDebouncedEffect } from '../../../utils/hooks'
import { DetailsForm } from '../../components/DetailsForm/DetailsForm'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

interface DetailsPageProps extends RouteComponentProps {
  branchId: string
}

const BaseDetailsPage = ({ branchId }: DetailsPageProps) => {
  const {
    advisor,
    appointmentType,
    branch,
    date,
    eventId,
    meetingMethodCategory,
    meetingMethod,
    serviceSubType,
    proficiency,
    loanAmount,
    setOrigin
  } = useBookingStore()

  const [startTime, setStartTime] = useState(new Date())

  useEffect(() => {
    setOrigin(AppointmentOriginEnum.EFS)
    mixpanelTrack(MixpanelEvents.DetailsPageLand, { pageLanded: 'Yes', page: currentPage() })
    setStartTime(new Date())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useDebouncedEffect(
    () => {
      if (!eventId) {
        fetchAvailability()
      }
      /* Note:  We purposely leave eventId out of the dependency array
       * otherwise event is release immediately after it is held */
      // tslint:disable-next-line: max-line-length
    },
    debounceTime,
    [branch, date, meetingMethodCategory, appointmentType, serviceSubType, proficiency, advisor, loanAmount]
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const detailsFormHandleSubmit = (): void => {
    mixpanelTrack(MixpanelEvents.DetailsPageNext, {
      clientType: convertClientType(appointmentType),
      appointmentSubtype: serviceSubType,
      appointmentMethod: meetingMethod,
      creditRequested: convertLoanAmount(loanAmount),
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      pageTime: secondsSinceTime(startTime)
    })
    navigate(routes.findALocation.path)
  }

  return (
    <>
      <Feedback />
      {
        <DetailsForm
          branchId={branchId}
          bigTitle={i18n.__(copy.Book.BigTitle)}
          littleTitle={i18n.__(copy.Book.LittleTitle)}
          submitButtonText={i18n.__(copy.Buttons.Continue)}
          handleSubmit={detailsFormHandleSubmit}
        />
      }
    </>
  )
}

export const DetailsPage = observer(BaseDetailsPage)
