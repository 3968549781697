import {
  Advisor,
  AppointmentOriginEnum,
  AppointmentTypeEnum,
  i18n,
  copy,
  ServiceSubTypes,
  AppointmentSubTypeEnum
} from '@atbdigitalteam/obs-shared-components'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

import {
  convertBoolean,
  convertClientType,
  currentPage,
  getWorkflowTeam,
  secondsSinceTime,
  timeRange
} from '../../../utils/mixpanel'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { ALBERTA_TIMEZONE } from '../../../../src/utils/constants'
import { getAdvisorByBranch, getBranch, holdTimeslot } from '../../../requests'
import { Feedback } from '../../components/Feedback'
import { ObsLiteForm } from '../../components/ObsLiteForm'
import {
  bookingStore,
  fetchAvailability,
  fetchAvailableDates,
  notificationService,
  releaseTimeslot,
  useBookingStore,
  useCalendarStore
} from '../../../injectables'
import { OBSLiteError } from '../../components/OBSLiteError'
import { routes } from '../../../routing'
import { DetailsFormValues } from '../../../types'
import { UIState } from '../../../styles'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export interface OBSLitePageProps extends RouteComponentProps {
  branchId: string
}

const branchToOrigin = {
  '402': AppointmentOriginEnum.ACQUISITION,
  '403': AppointmentOriginEnum.MORTGAGE_RENEWAL,
  '404': AppointmentOriginEnum.MORTGAGE_RENEWAL,
  '405': AppointmentOriginEnum.MORTGAGE_RENEWAL,
  '406': AppointmentOriginEnum.MORTGAGE_RENEWAL
}

const useStyles = makeStyles({
  button: {
    padding: 0,
    border: 'solid 2px transparent',
    '&:focus-visible,&:hover': {
      border: 'solid 2px #000025',
      backgroundColor: 'transparent'
    }
  },
  buttonText: {
    fontSize: '16px',
    color: 'rgb(0, 114, 240)',
    fontWeight: 400,
    padding: '16px 16px',
    lineHeight: '24px'
  }
})
export const BaseOBSLitePage = ({ branchId }: OBSLitePageProps) => {
  const {
    advisor,
    appointmentType,
    branch,
    bookingId,
    date,
    eventId,
    meetingMethod,
    meetingMethodCategory,
    proficiency,
    serviceSubType,
    timeSlot,
    setAdvisor,
    setAppointmentType,
    setBranch,
    setBookingId,
    setEventId,
    setOrigin,
    setServiceSubType,
    preFillFields
  } = useBookingStore()

  const { availableSlots, availableDates, setAvailableSlots, setAvailableDates } = useCalendarStore()
  const classes = useStyles()
  const [startTime, setStartTime] = useState(new Date())
  const [invalidURL, setInvalidURL] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [flowURL, setFlowURL] = useState('')

  const flowRedirect = {
    '400': '/prosper/book',
    '401': '/gws/book'
  }

  useEffect(() => {
    setStartTime(new Date())
  }, [])

  useEffect(() => {
    let mixpanelEvent

    if (invalidURL) {
      mixpanelEvent = MixpanelEvents.OBSLiteBadLink
    } else if (flowURL) {
      mixpanelEvent = MixpanelEvents.OBSLiteWrongWorkflow
    }

    if (mixpanelEvent) {
      mixpanelTrack(mixpanelEvent, {
        pageLanded: 'Yes',
        page: currentPage()
      })
    }
  }, [invalidURL, flowURL])

  useEffect(() => {
    if (bookingStore.origin) {
      mixpanelTrack(MixpanelEvents.OBSLitePageLand, {
        workflowTeam: getWorkflowTeam(bookingStore.origin),
        pageLanded: 'Yes',
        page: currentPage()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingStore.origin])

  useEffect(() => {
    const transit = branchId.length === 5 ? branchId.substring(1, 4) : branchId
    const origin = branchToOrigin[transit]

    if (origin === AppointmentOriginEnum.MORTGAGE_RENEWAL) {
      setAppointmentType(AppointmentTypeEnum.PERSONAL)
      setServiceSubType(AppointmentSubTypeEnum.MORTGAGES)
      preFillFields({ existingCustomer: true, description: 'Mortgage renewal' })
    }

    if (eventId) {
      setAvailableDates(undefined)
      setAvailableSlots(undefined)
      releaseTimeslot()
    }

    if (!branch) {
      setIsFetching(true)
      getBranch(transit)
        .then(branch => {
          setBranch(branch)
        })
        .catch(() => {
          setInvalidURL(true)
        })
        .finally(() => {
          setIsFetching(false)
        })
    }

    if (!origin && !invalidURL) {
      flowRedirect[transit] ? setFlowURL(flowRedirect[transit]) : setFlowURL(`/0${transit}9`) // EFS flow
    } else {
      setOrigin(origin)
    }

    if (!advisor) {
      const searchParams = new URLSearchParams(window.location.search)
      const advisorParam = searchParams.get('advisor') || undefined
      setAdvisor({ email: advisorParam } as Advisor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (branch && !advisor?.name) {
      setIsFetching(true)
      getAdvisorByBranch(branch.transit, advisor!.email)
        .then(advisorList => {
          const foundAdvisor = advisorList.find(item => item.email === advisor?.email)
          if (foundAdvisor) {
            setAdvisor(foundAdvisor)
          } else {
            setInvalidURL(true)
          }
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch])

  useEffect(() => {
    if (!eventId) {
      fetchAvailableDates()
    }
  }, [branch, meetingMethodCategory, serviceSubType, eventId])

  useEffect(() => {
    if (!eventId) {
      fetchAvailability()
    }
  }, [branch, date, meetingMethodCategory, serviceSubType, eventId])

  const error = {
    text: invalidURL ? i18n.__(copy.Error.InvalidURL) : i18n.__(copy.Error.InvalidFlow),
    buttonText: invalidURL ? i18n.__(copy.ContactUs.Label) : i18n.__(copy.Book.LittleTitle),
    buttonRedirect: invalidURL ? i18n.__(copy.ContactUs.Link) : window.location.origin + flowURL
  }

  function handleErrorClick() {
    const mixpanelEvent = flowURL ? MixpanelEvents.OBSLiteWrongWorkflowClick : MixpanelEvents.OBSLiteBadLinkContactUs
    mixpanelTrack(mixpanelEvent, { pageTime: secondsSinceTime(startTime) })
  }

  const handleSubmit = (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>): void => {
    // hold timeslot
    holdTimeslot(
      branch?.transit!,
      date!,
      timeSlot?.time!,
      timeSlot?.type!,
      appointmentType!,
      serviceSubType!,
      proficiency!,
      meetingMethod,
      bookingId,
      advisor?.email,
      bookingStore.origin
    )
      .then(response => {
        // on successful hold, track mixpanel and navigate to summary page
        actions.setSubmitting(false)
        if (response.status === 201) {
          setBookingId(response.data.bookingId)
          setEventId(response.data.eventId)
          mixpanelTrack(MixpanelEvents.OBSLitePageNext, {
            clientType: convertClientType(appointmentType),
            workflowTeam: getWorkflowTeam(bookingStore.origin),
            appointmentMethod: meetingMethod,
            appointmentSubtype: serviceSubType,
            page: currentPage(),
            appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
            teamMemberSelection: convertBoolean(advisor),
            appointmentTime: timeSlot ? timeRange(timeSlot.time!, timeSlot.type!) : 'not selected',
            lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
            branchLocation: branch?.name,
            availableDatesCount: availableDates?.length,
            availableSlotsCount: availableSlots?.length,
            pageTime: secondsSinceTime(startTime)
          })
          navigate(routes.summary.path)
        } else if (response.status === 204) {
          fetchAvailability()
          actions.setSubmitting(false)
          notificationService.createNotification(
            'Cannot proceed. Looks like someone has just taken your selected time slot',
            UIState.Info
          )
        }
      })
      .catch(() => {
        notificationService.createNotification('Something went wrong', UIState.Error)
        actions.setSubmitting(false)
      })
  }

  return isFetching ? (
    <></>
  ) : invalidURL || flowURL ? (
    <>
      <Feedback />
      <OBSLiteError
        bigTitle={i18n.__(copy.Book.BigTitle)}
        littleTitle={i18n.__(copy.Book.LittleTitle)}
        insideText={error.text}
        dialogueFooter={
          <Button
            className={classes.button}
            data-testid='error-button'
            href={error.buttonRedirect}
            target='_blank'
            onClick={handleErrorClick}
            disableRipple
          >
            <div className={classes.buttonText}>{error.buttonText}</div>
          </Button>
        }
      />
    </>
  ) : (
    <>
      <Feedback />
      <ObsLiteForm
        bigTitle={i18n.__(copy.Book.BigTitle)}
        littleTitle={i18n.__(copy.Book.LittleTitle)}
        submitButtonText={i18n.__(copy.Buttons.Continue)}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export const OBSLitePage = observer(BaseOBSLitePage)
