import {
  ActionButton,
  AppointmentOriginEnum,
  FeedbackOperationEnum,
  StringReplace,
  i18n,
  copy
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { globalHistory, navigate, RouteComponentProps } from '@reach/router'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import reactStringReplace from 'react-string-replace'

import { clearAllStores, useAuthentication, useBookingStore } from '../../../injectables'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import {
  convertClientType,
  currentPage,
  currentWorkflowPage,
  getWorkflow,
  getWorkflowTeam,
  secondsSinceTime
} from '../../../utils/mixpanel'
import { getShortBookingId } from '../../../utils/bookingId'
import { AppointmentSummary } from '../../components/AppointmentSummary'
import { ExpiredPageModal } from '../../components/ExpiredPageModal'
import { handleSubmit, FeedbackForm, FeedbackSubmitted, FeedbackValues } from '../../components/Feedback'
import { LeavePageModal } from '../../components/LeavePageModal'
import { PageTitle } from '../../components/PageTitle'
import { IntroParagraph } from './IntroParagraph'
import { BulletList } from '../../components/BulletList'
import { WealthOrigins } from '../../../utils/constants'
import { originUrl } from '../../../utils/urls'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'
import { BoldSubString } from '../../../translations'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    marginRight: '8px',
    marginLeft: '8px',
    marginBottom: '80px',
    marginTop: '62px'
  },
  appointmentSummary: {
    marginBottom: '8px'
  },
  bookingIdContainer: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '0.25rem'
  },
  divider: {
    maxHeight: '0',
    border: 'solid 0.5px #e4e4e4',
    margin: '0.5rem 0 0.5rem 0'
  },
  label: {
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 600
  },
  helpTextContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridRowGap: '0.25rem',
    marginBottom: '8px'
  },
  buttonMarginTop: {
    marginTop: '16px'
  },
  listItem: {
    marginBottom: '5px'
  },
  linkStyle: {
    textDecoration: 'underline',
    color: '#0072F0'
  }
})

const BaseConfirmationPage = (_props: RouteComponentProps) => {
  const classes = useStyles()
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [startTime, setStartTime] = useState<Date>()
  const [openLeavePageModal, setOpenLeavePageModal] = useState(false)
  const [openExpiredPageModal, setOpenExpiredPageModal] = useState(false)
  const { authToken, logout } = useAuthentication()

  const {
    advisor,
    appointmentType,
    appointmentTimeRange,
    branch,
    branchName,
    branchAddress,
    bookingId,
    meetingMethod,
    timeString,
    shortDateString,
    summaryServiceType,
    serviceSubType,
    feedbackRating,
    feedbackComments,
    province,
    employerName,
    setFeedbackRating,
    setFeedbackComments,
    email,
    meetingLink,
    phoneNumber,
    addToCalendarEvent,
    origin,
    bookingStoreToString,
    existingCustomer,
    timezoneLabel
  } = useBookingStore()

  const bookingStore = bookingStoreToString()

  const confirmationModalBody = (
    <>
      Your session has expired, but don't worry, your appointment has been booked. All the details you need will be in a
      confirmation email in your inbox shortly.
    </>
  )

  let modifyAndCancelSection = reactStringReplace(i18n.__(copy.Confirm.Reschedule.Body.Text), '%CANCEL_LINK%', () => (
    <Link
      key='cancel-link'
      className={classes.linkStyle}
      onClick={() => handleCancelClick()}
      href={`/cancel?bookingId=${getShortBookingId(bookingId)}`}
    >
      {i18n.__(copy.Confirm.Reschedule.Body.CancelLinkText)}
    </Link>
  ))
  modifyAndCancelSection = reactStringReplace(modifyAndCancelSection, '%MODIFY_LINK%', () => (
    <Link
      key='modify-link'
      className={classes.linkStyle}
      onClick={() => handleModifyClick()}
      href={`/modify?bookingId=${getShortBookingId(bookingId)}`}
    >
      {i18n.__(copy.Confirm.Reschedule.Body.ModifyLinkText)}
    </Link>
  ))

  const handleBookAnotherAppointment = (url: string) => {
    mixpanelTrack(MixpanelEvents.ConfirmatonPageBookAnother, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    navigate(url)
  }

  const handleModifyClick = () => {
    mixpanelTrack(MixpanelEvents.ConfirmationPageClickModify, {
      appointmentSubType: serviceSubType,
      pageTime: startTime ? secondsSinceTime(startTime) : '',
      appointmentMethod: meetingMethod,
      clientType: convertClientType(appointmentType),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
  }

  const handleCancelClick = () => {
    mixpanelTrack(MixpanelEvents.ConfirmationPageClickCancel, {
      appointmentSubType: serviceSubType,
      pageTime: startTime ? secondsSinceTime(startTime) : '',
      appointmentMethod: meetingMethod,
      clientType: convertClientType(appointmentType),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
  }

  const handleReturnToATB = () => {
    mixpanelTrack(MixpanelEvents.ConfirmationPageReturnToATB, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    logout()
  }

  const handleReturnToATBByExpiredModal = () => {
    mixpanelTrack(MixpanelEvents.ExpiredModalButtonClicked, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    navigate('https://www.atb.com/')
  }

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'POP') {
        window.scrollTo(0, 0)
        setOpenLeavePageModal(true)
        navigate('/confirmation')
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setStartTime(new Date())
    if (!bookingId) {
      mixpanelTrack(MixpanelEvents.ExpiredModalDisplayed, {
        page: currentPage(),
        workflowPage: currentWorkflowPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
      setOpenExpiredPageModal(true)
    } else {
      mixpanelTrack(MixpanelEvents.ConfirmationPageLand, {
        pageLanded: 'Yes',
        page: currentPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
    }
  }, [])

  return (
    <Grid data-testid='confirmation-page' container justifyContent='center'>
      <Grid container direction='column' spacing={2} wrap='nowrap' className={classes.formRoot}>
        <Grid item>
          <PageTitle littleTitle={i18n.__(copy.Confirm.LittleTitle)} bigTitle={i18n.__(copy.Confirm.BigTitle)} />
        </Grid>
        {meetingMethod && (
          <Grid item>
            <IntroParagraph
              branchName={branchName}
              dateString={shortDateString}
              timeString={timeString}
              meetingMethod={meetingMethod}
              phoneNumber={phoneNumber}
              timezoneLabel={timezoneLabel}
              origin={origin}
            />
          </Grid>
        )}
        <Grid item>
          <Typography variant='body2' data-testid='appointment-confirmation-text'>
            <>
              {BoldSubString(
                StringReplace(i18n.__(copy.Confirm.ParagraphText), { '%CUSTOMER_EMAIL%': email || '' }),
                'text'
              )}
            </>
          </Typography>
        </Grid>
        <Grid item data-testid='appointment-confirmation-email-info'>
          <div className={classes.helpTextContainer}>
            <BulletList
              items={[
                <div className={classes.listItem}>{i18n.__(copy.Confirm.ConfirmationEmailMoreInfo)}</div>,
                <div className={classes.listItem}>
                  {i18n.__(copy.Confirm.ConfirmationEmailEnsureReceived.Text)}
                  {WealthOrigins.includes(origin) && (
                    <Link
                      className={classes.linkStyle}
                      href={`mailto:${i18n.__(copy.Confirm.ConfirmationEmailEnsureReceived.Contact)}`}
                    >
                      {i18n.__(copy.Confirm.ConfirmationEmailEnsureReceived.Contact)}
                    </Link>
                  )}
                </div>
              ]}
            />
          </div>
        </Grid>
        <Grid item className={classes.appointmentSummary}>
          <AppointmentSummary
            branchName={branchName}
            branchAddress={branchAddress}
            serviceSummary={summaryServiceType}
            date={shortDateString}
            timeRange={appointmentTimeRange}
            advisor={advisor}
            meetingMethod={meetingMethod}
            meetingLink={meetingLink}
            enhanced
            addToCalendarEvent={addToCalendarEvent}
            province={province}
            bookingId={bookingId}
            origin={origin}
            existingGWS={existingCustomer && origin === AppointmentOriginEnum.GWS}
            employerName={employerName}
          />
        </Grid>
        <Grid item>
          <div className={classes.helpTextContainer}>
            <Typography className={classes.label} variant='h2'>
              {i18n.__(copy.Confirm.Reschedule.Label)}
            </Typography>
            <Typography variant='body2'>
              <>{modifyAndCancelSection}</>
            </Typography>
          </div>
        </Grid>
        <Formik
          initialValues={{
            feedbackRating: feedbackRating || 0,
            feedbackComments: feedbackComments || ''
          }}
          onSubmit={(values: FeedbackValues, actions: FormikHelpers<FeedbackValues>) =>
            handleSubmit(
              values,
              actions,
              feedbackRating!,
              feedbackComments!,
              FeedbackOperationEnum.BOOK,
              setFeedbackSubmitted,
              bookingStore,
              origin!,
              bookingId
            )
          }
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Grid item>
                <div className={classes.divider} />
              </Grid>
              <Grid item>
                {!feedbackSubmitted && (
                  <FeedbackForm
                    isSubmitting={isSubmitting}
                    ratingValue={values.feedbackRating}
                    textValue={values.feedbackComments}
                    onChangeRating={(value: number) => setFeedbackRating(value)}
                    onChangeText={(value: string) => setFeedbackComments(value)}
                    validate={{
                      rating: value => required(value, i18n.__(copy.Validation.required.feedbackRating)),
                      feedbackComments: value => sensitiveInfo(value)
                    }}
                    validateOnBlur
                  />
                )}
                {feedbackSubmitted && <FeedbackSubmitted />}
              </Grid>
              <Grid item>
                <div className={classes.divider} />
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid item>
          <ActionButton
            className={classes.buttonMarginTop}
            buttonText={authToken ? i18n.__(copy.Buttons.Logout) : i18n.__(copy.Buttons.ReturnToATB)}
            onClick={() => handleReturnToATB()}
            testid='return-to-atb-button'
          />
          {origin === AppointmentOriginEnum.EFS && (
            <ActionButton
              className={`${classes.buttonMarginTop} `}
              buttonText={i18n.__(copy.Buttons.BookAnotherAppointment)}
              onClick={() => handleBookAnotherAppointment('/book')}
              testid='book-another-appt-button'
              variant='outlined'
            />
          )}
        </Grid>
        <LeavePageModal
          data-testid='leave-page-modal'
          open={openLeavePageModal}
          onClose={() => setOpenLeavePageModal(false)}
          bookingId={bookingId}
          newBookingURL={originUrl(origin, branch?.transit, advisor?.email)}
        />
        <ExpiredPageModal
          data-testid='expired-page-modal'
          open={openExpiredPageModal}
          displayCloseButton={false}
          modalBody={confirmationModalBody}
          modalButtonText='Take me home'
          onModalButtonClick={() => handleReturnToATBByExpiredModal()}
        />
      </Grid>
    </Grid>
  )
}

export const ConfirmationPage = observer(BaseConfirmationPage)
