import mixpanel from 'mixpanel-browser'

const defaultValues = {
  userGUID: ''
}

export const mixpanelSetup = async () => {
  const mixpanelOptions =
    process.env.MIXPANEL_ENV === 'nonprod' ? { debug: true, opt_out_tracking_by_default: true } : {}
  mixpanel.init(process.env.MIXPANEL_TOKEN as string, mixpanelOptions)
  mixpanel.register({ platformUsed: 'Online Appointment Booking' })
}

export const setMixpanelUser = (decodedToken: object) => {
  const externalUuid = decodedToken['externalUUID']
  if (externalUuid !== undefined) {
    defaultValues.userGUID = externalUuid
    mixpanel.identify(externalUuid)
    mixpanel.people.set('userGUID', externalUuid)
  }
}

export const mixpanelTrack = (eventName: string, props = {}) => {
  const trackProps = {
    ...defaultValues,
    ...props
  }
  mixpanel.track(eventName, trackProps)
}
