import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Popover from '@material-ui/core/Popover'
import SvgIcon from '@material-ui/core/SvgIcon'
import ToolBar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Info from '@material-ui/icons/Info'
import React, { useState, ReactElement } from 'react'
import { currentPage, getWorkflow, getWorkflowTeam, currentWorkflowPage } from '../../utils/mixpanel'
import { MixpanelEvents } from '../../translations/mixpanelEvents'
import { useBookingStore } from '../../injectables'
import { observer } from 'mobx-react'
import { AppointmentOriginEnum } from '@atbdigitalteam/obs-shared-components'
import { Phone, LocationOn } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { mixpanelTrack } from '../../utils/mixpanelWrapper'

const useStyles = makeStyles(() => ({
  root: {
    background: 'unset'
  },
  menuBar: {
    background: '#0072F0',
    marginBottom: '10px'
  },
  toolbar: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  skipToMainContentLink: {
    marginLeft: '7%',
    position: 'absolute',
    transform: 'translateY(-100%)',
    transition: 'transform 0.3s',
    '&:focus': {
      transform: 'translateY(0%)'
    },
    color: 'white'
  },
  atbIcon: {
    backgroundImage:
      'url(https://grow-static-content.poweredbygrow.com/atb/rebrand/ATBPoweringPossibillity/ATB-PoweringPossibility_White_RGB.svg)',
    width: '177.21px',
    height: '44px',
    backgroundSize: '177.21px 44px'
  },
  prosperIcon: {
    backgroundImage:
      'url(https://grow-static-content.poweredbygrow.com/atb/rebrand/ATBProsper/ATB-Prosper_White_RGB.svg)',
    width: '177.21px',
    height: '44px',
    backgroundSize: '177.21px 44px'
  },
  wealthIcon: {
    backgroundImage:
      'url(https://grow-static-content.poweredbygrow.com/atb/rebrand/ATBWealth/ATB-Wealth_White_RGB.svg)',
    width: '177.21px',
    height: '44px',
    backgroundSize: '177.21px 44px'
  },
  logo: {
    justifySelf: 'center',
    alignSelf: 'center'
  },
  infoIconButton: {
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 5px'
    },
    justifySelf: 'flex-end'
  },
  closeIconButton: {
    padding: '0',
    justifySelf: 'flex-end'
  },
  icon: {
    color: 'white'
  },
  popoverContent: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px'
  },
  popoverCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '6px',
    fontSize: '14px',
    alignItems: 'center'
  },
  popoverCellTitle: {
    fontWeight: 600
  },
  contactUsTitle: {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: '18px',
    alignSelf: 'center',
    fontWeight: 600
  },
  atbGrey: {
    color: '#44444D'
  }
}))

const BaseHeader = (): ReactElement => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const classes = useStyles()
  const { origin } = useBookingStore()

  const handleInfoClick = (event: React.MouseEvent) => {
    setPopoverAnchorEl(event.currentTarget as unknown as React.SetStateAction<null>)
    mixpanelTrack(MixpanelEvents.HelpIcon, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage()
    })
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  const handlePhoneClick = () => {
    mixpanelTrack(MixpanelEvents.HelpPhone, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage()
    })
  }

  const handleAtbLogoClick = () => {
    mixpanelTrack(MixpanelEvents.ATBLogo, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage()
    })
  }

  const handleContactUsClick = () => {
    mixpanelTrack(MixpanelEvents.HelpContactUs, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage()
    })
  }

  const infoPopoverOpen = Boolean(popoverAnchorEl)

  let logoClass

  if (origin) {
    if (origin === AppointmentOriginEnum.PROSPER) {
      logoClass = classes.prosperIcon
    } else if (origin === AppointmentOriginEnum.GWS) {
      logoClass = classes.wealthIcon
    } else {
      logoClass = classes.atbIcon
    }
  } else {
    if (window.location.href.search(/cancel|modify/i)) {
      logoClass = classes.atbIcon
    }
  }

  return (
    <AppBar position='static' elevation={0} className={classes.root} data-testid='header'>
      <div className={classes.menuBar}>
        <Link href='#main-content' className={classes.skipToMainContentLink}>
          Skip to main content
        </Link>
        <ToolBar className={classes.toolbar}>
          <div></div>
          <Link
            aria-label='ATB logo redirecting to www.atb.com'
            data-testid='atb-logo'
            href='https://www.atb.com/'
            className={classes.logo}
            onClick={handleAtbLogoClick}
          >
            <SvgIcon className={logoClass} />
          </Link>
          <IconButton
            className={classes.infoIconButton}
            onClick={handleInfoClick}
            data-testid='info-icon-button'
            aria-expanded={infoPopoverOpen}
            aria-label='help'
          >
            <Info fontSize='small' className={classes.icon} />
          </IconButton>
          <Popover
            data-testid='popover'
            open={infoPopoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: { boxShadow: '1px 1px 5px rgba(0, 0, 37, 0.5)' }
            }}
          >
            <div className={classes.popoverContent} data-testid='popover-content' role='dialog' aria-label='Contact Us'>
              <div className={classes.popoverCell}>
                <Typography className={classes.contactUsTitle}>Contact us</Typography>
                <IconButton
                  className={classes.closeIconButton}
                  onClick={handlePopoverClose}
                  data-testid='close-icon-button'
                  aria-label='Close'
                  edge={false}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={classes.popoverCell}>
                <Phone className={classes.atbGrey} />
                <Typography className={classes.popoverCellTitle} variant='body2'>
                  Call:
                </Typography>
                <Link href='tel:+1800-332-8383' target='_blank' onClick={handlePhoneClick}>
                  +1-800-332-8383
                </Link>
              </div>

              <div className={classes.popoverCell}>
                <LocationOn className={classes.atbGrey} />
                <Typography className={classes.popoverCellTitle} variant='body2'>
                  Visit:
                </Typography>
                <Link href='https://www.atb.com/resources/contact-us/' target='_blank' onClick={handleContactUsClick}>
                  Contact Us Page
                </Link>
              </div>
            </div>
          </Popover>
        </ToolBar>
      </div>
    </AppBar>
  )
}

export const Header = observer(BaseHeader)
