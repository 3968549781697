import {
  ActionButton,
  AppointmentOriginEnum,
  ContactMethod,
  CustomerInformation,
  LabelledCheckbox,
  PreferredContactMethodEnum,
  i18n,
  copy,
  ServiceSubTypes
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { navigate, RouteComponentProps } from '@reach/router'
import { differenceInCalendarDays } from 'date-fns'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { UIState } from '../../../../src/styles'
import {
  clearAllStores,
  notificationService,
  releaseTimeslot,
  useBranchStore,
  useBookingStore,
  useAuthentication
} from '../../../injectables'
import { confirmAppointment, verifyRecaptcha } from '../../../requests'
import { StringReplace } from '../../../translations'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import {
  convertBoolean,
  convertClientType,
  currentPage,
  currentWorkflowPage,
  dayOfWeekSet,
  getWorkflow,
  getWorkflowTeam,
  secondsSinceTime,
  timeOfDaySet,
  timeRange
} from '../../../utils/mixpanel'
import { AppointmentSummary } from '../../components/AppointmentSummary'
import { ExpiredPageModal } from '../../components/ExpiredPageModal'
import { Feedback } from '../../components/Feedback'
import { GoogleRecaptcha } from '../../components/GoogleRecaptcha'
import { HoldingTimer } from '../../components/HoldingTimer'
import { PageTitle } from '../../components/PageTitle'
import { ALBERTA_TIMEZONE } from '../../../utils/constants'
import { emailFormat, nameFormat, phoneAreaCode, phoneFormat, required } from '../../../utils/fieldValidation'
import { Logout } from '../../components/Logout'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const { required: requiredText } = copy.Validation

interface SummaryPageValues {
  businessName?: string
  firstName?: string
  lastName?: string
  email?: string
  employerName?: string
  phoneNumber?: string
  preferredContactMethod?: PreferredContactMethodEnum
  existingCustomer?: boolean
  caslConsent?: boolean
  reminderRequested?: boolean
  origin?: AppointmentOriginEnum
}

const useStyles = makeStyles({
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    marginRight: '8px',
    marginLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%'
  },
  holdingTimer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  buttonRow: {
    marginTop: '32px'
  },
  continueButton: {
    width: '220px',
    marginLeft: 'auto'
  }
})

export const BaseSummaryPage = (_props: RouteComponentProps) => {
  const classes = useStyles()
  const [recaptchaValidated, setRecaptchaValidated] = useState(false)
  const [startTime, setStartTime] = useState(new Date())
  const [openExpiredPageModal, setOpenExpiredPageModal] = useState(false)
  const { isAuthenticated, logout } = useAuthentication()

  const { dayOfWeek, timeOfDay } = useBranchStore()

  const {
    advisor,
    bpId,
    date,
    meetingMethod,
    branchName,
    branchAddress,
    bookingId,
    description,
    eventId,
    caseId,
    summaryServiceType,
    shortDateString,
    appointmentTimeRange,
    appointmentType,
    serviceSubType,
    transit,
    businessName,
    firstName,
    lastName,
    email,
    employerName,
    province,
    phoneNumber,
    timeSlot,
    preferredContactMethod,
    existingCustomer,
    caslConsent,
    preFilledFields,
    reminderRequested,
    loginType,
    origin,
    loanAmount,
    setBusinessName,
    setFirstName,
    setLastName,
    setEmail,
    setEmployerName,
    setPhoneNumber,
    setPreferredContactMethod,
    setExistingCustomer,
    setMeetingLink,
    setReminderRequested
  } = useBookingStore()

  const handleUnload = useCallback(() => {
    if (eventId) {
      releaseTimeslot()
    }
  }, [eventId])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload])

  const onClickEdit = (event: React.MouseEvent) => {
    event.preventDefault()
    navigate(-1)
    mixpanelTrack(MixpanelEvents.SummaryPageEdit, {
      clientType: convertClientType(appointmentType),
      appointmentSubtype: serviceSubType,
      pageTime: secondsSinceTime(startTime),
      branchLocation: branchName,
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      appointmentMethod: meetingMethod,
      appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
      appointmentTime: timeRange(timeSlot?.time!, timeSlot?.type!),
      lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
      teamMember: advisor?.email,
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
  }

  const onHoldingTimerExpire = () => {
    releaseTimeslot(true)
  }

  useEffect(() => window.scrollTo(0, 0), [])

  const siteKey = process.env.RECAPTCHA_SITE_KEY || ''

  useEffect(() => {
    if (siteKey === 'unittest') {
      setRecaptchaValidated(true)
    }
  }, [])

  const onVerifyRecaptcha = (token: string | null) => {
    verifyRecaptcha(token).then(response => {
      if (response.data.success === true) {
        setRecaptchaValidated(true)
      } else {
        notificationService.createNotification('ReCAPTCHA verification failed, please try again.', UIState.Error)
      }
    })
  }

  const handleBookAnotherAppointment = () => {
    mixpanelTrack(MixpanelEvents.ExpiredModalButtonClicked, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    navigate('/book')
  }

  const onRecaptchaExpire = () => {
    setRecaptchaValidated(false)
  }

  const showReminderRequested = date ? differenceInCalendarDays(date, new Date()) >= 2 : false

  const handleSubmit = (values: SummaryPageValues, actions: FormikHelpers<SummaryPageValues>): void => {
    if (eventId) {
      confirmAppointment(
        transit,
        eventId,
        appointmentType!,
        serviceSubType!,
        email!,
        employerName!,
        province!,
        firstName!,
        lastName!,
        branchAddress,
        bookingId!,
        description!,
        businessName!,
        phoneNumber!,
        caslConsent,
        preferredContactMethod!,
        reminderRequested,
        false,
        existingCustomer,
        meetingMethod,
        advisor,
        bpId,
        loginType,
        origin,
        caseId,
        loanAmount
      )
        .then(response => {
          actions.setSubmitting(false)
          if (response.status === 201) {
            mixpanelClick()
            setMeetingLink(response.data.hangoutLink)
            navigate('/confirmation')
          }
        })
        .catch(error => {
          actions.setSubmitting(false)
          if (error.response.status === 400) {
            notificationService.createNotification(
              'Cannot proceed. Looks like someone has just taken your selected time slot',
              UIState.Info
            )
            navigate(-1)
          } else {
            notificationService.createNotification('Something went wrong', UIState.Error)
          }
        })
    }
  }

  useEffect(() => {
    setStartTime(new Date())
    if (!bookingId) {
      mixpanelTrack(MixpanelEvents.ExpiredModalDisplayed, {
        page: currentPage(),
        workflowPage: currentWorkflowPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
      setOpenExpiredPageModal(true)
    } else {
      mixpanelTrack(MixpanelEvents.SummaryPageLand, {
        pageLanded: 'Yes',
        page: currentPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
    }
  }, [])

  const mixpanelClick = () => {
    mixpanelTrack(MixpanelEvents.SummaryPageConfirm, {
      methodOfContact: preferredContactMethod,
      existingCustomer: convertBoolean(existingCustomer),
      pageTime: secondsSinceTime(startTime),
      reminderOptIn: convertBoolean(showReminderRequested && reminderRequested),
      branchLocation: branchName,
      clientType: convertClientType(appointmentType),
      appointmentSubtype: serviceSubType,
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      appointmentMethod: meetingMethod,
      appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
      appointmentTime: timeRange(timeSlot?.time!, timeSlot?.type!),
      lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
      teamMember: advisor?.email,
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      dayFilter: dayOfWeekSet(dayOfWeek),
      timeFilter: timeOfDaySet(timeOfDay)
    })
  }

  return (
    <Formik
      initialValues={
        {
          businessName: businessName || '',
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          employerName: employerName || '',
          phoneNumber: phoneNumber || '',
          preferredContactMethod: preferredContactMethod || '',
          existingCustomer: existingCustomer || false,
          caslConsent: caslConsent || false,
          reminderRequested: reminderRequested || false,
          origin
        } as SummaryPageValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <Feedback />
          <Grid data-testid='summary-page' container justifyContent='center'>
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItem}>
                <PageTitle
                  littleTitle={i18n.__(copy.Book.LittleTitle)}
                  bigTitle={i18n.__(copy.Summary.BigTitle)}
                  step={i18n.__(copy.Book.StepLabels.Summary)}
                />
              </Grid>
              <Grid item className={classes.formItem}>
                <AppointmentSummary
                  branchName={branchName}
                  branchAddress={branchAddress}
                  serviceSummary={summaryServiceType}
                  date={shortDateString}
                  timeRange={appointmentTimeRange}
                  advisor={advisor}
                  onClickEdit={onClickEdit}
                  meetingMethod={meetingMethod}
                  origin={origin}
                  province={province}
                  existingGWS={existingCustomer}
                />
              </Grid>
              <Grid item className={classes.holdingTimer}>
                <HoldingTimer durationSeconds={60 * 5} onExpire={onHoldingTimerExpire} />
              </Grid>
              <Grid item className={classes.formItem}>
                <CustomerInformation
                  appointmentType={appointmentType}
                  origin={origin}
                  onChangeBusinessName={(value: string) => setBusinessName(value)}
                  onChangeFirstName={(value: string) => setFirstName(value)}
                  onChangeLastName={(value: string) => setLastName(value)}
                  onChangeEmail={(value: string) => setEmail(value)}
                  onChangeEmployerName={(value: string) => setEmployerName(value)}
                  onChangePhoneNumber={(value: string) => setPhoneNumber(value)}
                  onChangeExistingCustomer={(value: boolean) => setExistingCustomer(value)}
                  disabled={{
                    firstName: preFilledFields.firstName,
                    lastName: preFilledFields.lastName,
                    existingCustomer:
                      origin === AppointmentOriginEnum.PROSPER ||
                      origin === AppointmentOriginEnum.GWS ||
                      preFilledFields.existingCustomer
                  }}
                  validators={{
                    validateFirstName: value => required(value, i18n.__(requiredText.firstName)) || nameFormat(value),
                    validateLastName: value => required(value, i18n.__(requiredText.lastName)) || nameFormat(value),
                    validateEmail: value => required(value, i18n.__(requiredText.email)) || emailFormat(value),
                    validateEmployerName: value => required(value, i18n.__(requiredText.employerName)),
                    validatePhoneNumber: value =>
                      required(value, i18n.__(requiredText.phoneNumber)) || phoneFormat(value) || phoneAreaCode(value)
                  }}
                  validateOnBlur
                />
              </Grid>
              <Grid item className={classes.formItem}>
                <ContactMethod
                  name='preferredContactMethod'
                  advisorName={advisor?.name || undefined}
                  data-testid='preferred-contact-method'
                  ariaLabel={i18n.__(copy.PreferredContactMethod.Placeholder)}
                  ariaRequired='true'
                  placeholder={i18n.__(copy.PreferredContactMethod.Placeholder)}
                  onChange={(value: string) => setPreferredContactMethod(value)}
                  showSMSoption={origin === AppointmentOriginEnum.EFS}
                  validate={value => required(value, i18n.__(requiredText.preferredContactMethod))}
                  validateOnBlur
                />
              </Grid>
              {showReminderRequested && (
                <Grid item className={classes.formItem} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <LabelledCheckbox
                    data-testid='reminder-checkbox'
                    label={StringReplace(i18n.__(copy.Reminder.Label), {
                      '%REMINDER_TYPE%':
                        preferredContactMethod === PreferredContactMethodEnum.TEXT
                          ? i18n.__(copy.Reminder.TextMsg)
                          : i18n.__(copy.Reminder.Email)
                    })}
                    name='reminderRequested'
                    onChange={(value: boolean) => setReminderRequested(value)}
                  />
                </Grid>
              )}
              <Grid item className={classes.formItem}>
                <GoogleRecaptcha
                  siteKey={siteKey}
                  onChangeRecaptcha={onVerifyRecaptcha}
                  onRecaptchaExpire={onRecaptchaExpire}
                />
              </Grid>

              <Grid item className={classes.formItem}>
                <Grid container direction='row' className={classes.buttonRow}>
                  <Grid item>
                    <Logout
                      testid='cancel-button'
                      loggedIn={isAuthenticated()}
                      text={i18n.__(copy.Buttons.CancelShort)}
                      onClick={() => logout()}
                    />
                  </Grid>
                  <Grid item className={classes.continueButton}>
                    <ActionButton
                      buttonText={i18n.__(copy.Buttons.Confirm)}
                      submitting={isSubmitting}
                      disabled={
                        !recaptchaValidated ||
                        isSubmitting ||
                        !firstName ||
                        !lastName ||
                        !email ||
                        !phoneNumber ||
                        !preferredContactMethod ||
                        (origin === AppointmentOriginEnum.GWS && !employerName)
                      }
                      type='submit'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <ExpiredPageModal
                data-testid='expired-page-modal'
                modalBody={i18n.__(copy.Modals.ExpiredPage)}
                open={openExpiredPageModal}
                displayCloseButton={false}
                modalButtonText='Booking page'
                onModalButtonClick={() => handleBookAnotherAppointment()}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const SummaryPage = observer(BaseSummaryPage)
