import {
  ActionButton,
  Branch,
  BranchSearchField,
  DialogueDisplay,
  DayOfWeek,
  TimeOfDay,
  i18n,
  copy
} from '@atbdigitalteam/obs-shared-components'
import { Wrapper } from '@googlemaps/react-wrapper'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { globalHistory, navigate, RouteComponentProps } from '@reach/router'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'

import {
  clearAllStores,
  useAuthentication,
  useBookingStore,
  useBranchStore,
  useFeatureStore
} from '../../../injectables'
import { geocode } from '../../../requests/geocode'
import { routes } from '../../../routing/routes'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { FORM_WIDTH } from '../../../utils/constants'
import { required } from '../../../utils/fieldValidation'
import {
  currentPage,
  currentWorkflowPage,
  dayOfWeekSet,
  getWorkflow,
  getWorkflowTeam,
  secondsSinceTime,
  timeOfDaySet
} from '../../../utils/mixpanel'
import { BranchListField } from '../../components/BranchListField'
import { ExpiredPageModal } from '../../components/ExpiredPageModal'
import { Feedback } from '../../components/Feedback'
import { LatLongObject, LocationSearch } from '../../components/LocationSearch'
import { PageTitle } from '../../components/PageTitle'
import { ShowAvailabilityCheckbox } from '../../components/ShowAvailabilityCheckbox'
import { Logout } from '../../components/Logout'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || ''

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: FORM_WIDTH,
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  buttonRow: {
    marginTop: '32px'
  },
  backButton: {
    width: '85px'
  },
  cancelButton: {
    marginLeft: '8px'
  },
  continueButton: {
    width: '119px',
    marginLeft: 'auto'
  }
})

export const BaseFindALocationPage = (_props: RouteComponentProps) => {
  const startTime = useRef<Date>(new Date())
  const classes = useStyles()
  const {
    branches,
    moreAvailable,
    nextPage,
    isFetching,
    setIsFetching,
    resetSearchResults,
    onlyShowAvailable,
    setOnlyShowAvailable,
    googleMapsDown,
    dayOfWeek,
    setDayOfWeek,
    timeOfDay,
    setTimeOfDay
  } = useBranchStore()
  const {
    appointmentType,
    branch,
    proficiency,
    serviceSubType,
    address,
    latLong,
    blockedLocation,
    setBranch,
    setLatLong,
    loanAmount,
    origin
  } = useBookingStore()
  const { skipFindALocation, setSkipFindALocation } = useFeatureStore()
  const { isAuthenticated, logout } = useAuthentication()

  const [openExpiredPageModal, setOpenExpiredPageModal] = useState(false)
  const [isUserInput, setIsUserInput] = useState(true)

  const handleSubmit = () => {
    mixpanelTrack(MixpanelEvents.FindALocationPageNext, {
      branchLocation: branch?.name,
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      timeFilter: timeOfDaySet(timeOfDay),
      dayFilter: dayOfWeekSet(dayOfWeek)
    })
    navigate(routes.findATime.path)
  }

  const handleBack = () => {
    mixpanelTrack(MixpanelEvents.FindALocationPageBack, {
      branchLocation: branch?.name,
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
    resetSearchResults()
    navigate('/book')
  }

  const getNextPage = (coordinates?: LatLongObject) =>
    nextPage(coordinates || latLong, appointmentType, proficiency, loanAmount)

  const newSearch = (coordinates?: LatLongObject) => {
    resetSearchResults()
    return getNextPage(coordinates)
  }

  useEffect(() => {
    if (branch && skipFindALocation) {
      setSkipFindALocation(false)
      navigate(routes.findATime.path)
    }
  }, [branch, branches])

  useEffect(() => {
    if (address && !googleMapsDown && isUserInput) {
      setIsFetching(true)
      geocode(address)
        .then(async data => {
          const coordinates = (data.results || [])[0]?.geometry?.location
          if (
            coordinates &&
            (!branches ||
              branches.length === 0 ||
              coordinates.lat !== latLong?.latitude ||
              coordinates.lng !== latLong?.longitude)
          ) {
            setLatLong(coordinates.lat, coordinates.lng)
            await newSearch({ latitude: coordinates.lat, longitude: coordinates.lng })
          }
        })
        .finally(() => setIsFetching(false))
    }
  }, [address, googleMapsDown, isUserInput])

  useEffect(() => {
    if (!serviceSubType) {
      mixpanelTrack(MixpanelEvents.ExpiredModalDisplayed, {
        page: currentPage(),
        workflowPage: currentWorkflowPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
      setOpenExpiredPageModal(true)
    } else {
      mixpanelTrack(MixpanelEvents.FindALocationPageLand, { pageLanded: 'Yes', page: currentPage() })
    }
  }, [])

  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'POP') {
        handleBack()
      }
    })

    return unsubscribe
  }, [])

  const handleBookAnotherAppointment = () => {
    mixpanelTrack(MixpanelEvents.ExpiredModalButtonClicked, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    navigate('/book')
  }

  const handleShowMoreClick = () => {
    mixpanelTrack(MixpanelEvents.FindALocationPageShowMore, {
      page: currentPage(),
      pageTime: secondsSinceTime(startTime.current)
    })
    return getNextPage()
  }

  const handleOnlyShowAvailableChange = () => {
    if (!isFetching) {
      setOnlyShowAvailable(!onlyShowAvailable)
      mixpanelTrack(MixpanelEvents.ShowAvailableBranchesClicked, {
        page: currentPage(),
        pageTime: secondsSinceTime(startTime.current)
      })

      if (latLong) {
        newSearch()
      }
    }
  }

  return (
    <Formik
      initialValues={{
        branch: branch?.name ? branch : null
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Feedback />
          <Grid
            data-testid='find-a-location-form'
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle
                  littleTitle={i18n.__(copy.Book.LittleTitle)}
                  bigTitle={i18n.__(copy.Book.FindLocationBigTitle)}
                  step={i18n.__(copy.Book.StepLabels.FindLocation)}
                />
              </Grid>
              {googleMapsDown ? (
                <Grid item className={classes.formItem} data-testid='branch-search-field'>
                  <BranchSearchField
                    name='branch'
                    options={branches}
                    label='Search branch name, city or street address'
                    onChange={(newValue: Branch | null) => setBranch(newValue)}
                    validate={value => required(value, i18n.__(copy.Validation.required.branch))}
                    validateOnBlur
                  />
                </Grid>
              ) : (
                <Grid item className={classes.formItem} data-testid='location-search-wrapper'>
                  <Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} authReferrerPolicy='origin'>
                    <LocationSearch setIsUserInput={setIsUserInput} disabled={isFetching} />
                  </Wrapper>
                </Grid>
              )}

              {!googleMapsDown &&
                (blockedLocation && !address ? (
                  <Grid item className={classes.formItem}>
                    <DialogueDisplay id='blocked-location-access' displayMessage={i18n.__(copy.UseMyLocationText)} />
                  </Grid>
                ) : (
                  <>
                    {address && (
                      <Grid item className={classes.formItem}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '28px' }}>
                          <TimeOfDay
                            value={timeOfDay}
                            disabled={isFetching}
                            onChange={value => {
                              setTimeOfDay(value)
                              newSearch()
                            }}
                          />
                          <DayOfWeek
                            value={dayOfWeek}
                            disabled={isFetching}
                            onChange={value => {
                              setDayOfWeek(value)
                              newSearch()
                            }}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      className={classes.formItemNoMargin}
                      style={{ paddingTop: 0, marginTop: '-10px' }}
                      data-testid='location-availability-checkbox'
                    >
                      <ShowAvailabilityCheckbox
                        checked={onlyShowAvailable}
                        onChange={handleOnlyShowAvailableChange}
                        disabled={isFetching}
                      />
                    </Grid>

                    {isFetching && !(branches.length > 0 && latLong) && (
                      <Grid item className={classes.formItem}>
                        <CircularProgress style={{ marginLeft: '45%' }} />
                      </Grid>
                    )}

                    {branches.length > 0 && latLong && (
                      <Grid item className={classes.formItem} style={{ paddingTop: 0 }} data-testid='branch-list-field'>
                        <BranchListField
                          name='branch'
                          options={branches}
                          onChange={(newValue: Branch | null) => setBranch(newValue)}
                          onShowMore={moreAvailable ? handleShowMoreClick : undefined}
                          validate={value => required(value, i18n.__(copy.Validation.required.branch))}
                          validateOnBlur
                        />
                      </Grid>
                    )}
                  </>
                ))}

              <Grid item className={classes.formItem}>
                <Grid container direction='row' className={classes.buttonRow}>
                  <Grid item className={classes.backButton}>
                    <ActionButton
                      testid='back-button'
                      buttonText={i18n.__(copy.Buttons.Back)}
                      type='button'
                      variant='outlined'
                      onClick={handleBack}
                    />
                  </Grid>
                  <Grid item className={classes.cancelButton}>
                    <Logout
                      testid='cancel-button'
                      loggedIn={isAuthenticated()}
                      text={i18n.__(copy.Buttons.CancelShort)}
                      onClick={() => logout()}
                    />
                  </Grid>
                  <Grid item className={classes.continueButton}>
                    <ActionButton
                      buttonText={i18n.__(copy.Buttons.Continue)}
                      submitting={isSubmitting}
                      disabled={isSubmitting || !values.branch}
                      type='submit'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <ExpiredPageModal
                data-testid='expired-page-modal'
                modalBody={i18n.__(copy.Modals.ExpiredPage)}
                open={openExpiredPageModal}
                displayCloseButton={false}
                modalButtonText='Booking page'
                onModalButtonClick={() => handleBookAnotherAppointment()}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const FindALocationPage = observer(BaseFindALocationPage)
