import {
  ActionButton,
  Advisor,
  AdvisorSelectionField,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  AppointmentTypeField,
  Branch,
  BranchSearchField,
  DatePickerField,
  DialogueDisplay,
  LoanAmountEnum,
  LoanAmountField,
  MeetingMethodEnum,
  MeetingMethodField,
  ServiceTypeSelectField,
  TextInputField,
  TimeSlot,
  TimeSlotPickerField,
  i18n,
  copy,
  Options
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'

import Button from '@material-ui/core/Button'
import { useBookingStore, useBranchStore, useCalendarStore, useFeatureStore } from '../../../injectables'
import { getAdvisorList } from '../../../requests'
import { StringReplace } from '../../../translations'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { findBranch } from '../../../utils/findBranch'
import { minutesToUserFacingTimeString } from '../../../utils/minutesToUserFacingTimeString'
import { StatusNotification } from '../StatusNotification'
import { PageTitle } from '../PageTitle'
import { DetailsFormValues } from '../../../types'
import { currentPage } from '../../../utils/mixpanel'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'

export interface BookingDetailsFormProps {
  branchId: string
  bigTitle: string
  littleTitle: string
  submitButtonText: string
  handleSubmit: (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => void
}

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  statusNotificationContainer: {
    marginBottom: '32px',
    paddingRight: '16px',
    paddingLeft: '16px',
    width: '100%'
  },
  afterInfoText: {
    fontSize: '14px',
    paddingTop: '8px'
  },
  labelText: {
    fontSize: '14px',
    marginBottom: '8px'
  },
  inlineLink: {
    textDecoration: 'underline',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0072F0',
    cursor: 'pointer'
  },
  learnMoreButton: {
    padding: 0,
    border: 'solid 2px transparent',
    '&:focus-visible,&:hover': {
      border: 'solid 2px #000025',
      backgroundColor: 'transparent'
    }
  },
  learnMoreButtonText: {
    fontSize: '14px',
    color: 'rgb(0, 114, 240)',
    fontWeight: 700,
    padding: '16px 16px',
    lineHeight: '24px'
  }
})

export const BaseBookingDetailsForm = ({
  branchId,
  bigTitle,
  littleTitle,
  submitButtonText,
  handleSubmit
}: BookingDetailsFormProps) => {
  const [advisors, setAdvisors] = useState<Advisor[]>([])

  const classes = useStyles()

  const {
    advisor,
    bookingId,
    meetingMethod,
    meetingMethodCategory,
    appointmentLength,
    appointmentType,
    branch,
    date,
    description,
    minDate,
    bpId,
    serviceSubType,
    timeSlot,
    evaluateDateTimeRendering,
    proficiency,
    shortDateString,
    loanAmount,
    origin,
    setAdvisor,
    setMeetingMethod,
    setAppointmentType,
    setBranch,
    setDescription,
    setDate,
    setServiceSubType,
    setTimeSlot,
    setLoanAmount,
    timezoneLabel
  } = useBookingStore()

  const { availableSlots, availableDates, isFetching } = useCalendarStore()
  const { branchAlertNotification } = useFeatureStore()

  const { branches } = useBranchStore(true)

  const requiredText = copy.Validation.required
  const branchSearchFieldRef = useRef<HTMLElement | null>(null)

  const handleLearnMoreButtonClick = () => {
    mixpanelTrack(MixpanelEvents.SuggestDigitalOptions, {
      appointmentSubtype: serviceSubType,
      clientType: appointmentType,
      page: currentPage()
    })
  }

  const handleNoAppointmentsClick = () => {
    setBranch(null)
    window.scrollTo(0, 0)
    branchSearchFieldRef.current?.focus()
  }

  useEffect(() => {
    let transit

    if (branch && branch.transit?.length === 5) {
      transit = branch.transit
    } else if (!branch && branchId.length === 5) {
      transit = branchId
    }

    if (transit) {
      const matchedBranch = findBranch(branches, transit)

      if (matchedBranch) {
        setBranch(matchedBranch as Branch)
      }
    }
  }, [branch, branches])

  useEffect(() => {
    if (branch && meetingMethod && appointmentType && proficiency !== undefined && serviceSubType) {
      getAdvisorList(
        branch.transit,
        appointmentType,
        proficiency,
        serviceSubType,
        meetingMethod,
        bookingId,
        loanAmount,
        origin
      ).then(advisorList => {
        if (advisor && !advisorList.map(a => a.email).includes(advisor.email)) {
          setAdvisor()
        }
        setAdvisors(advisorList)
      })
    }
    // tslint:disable-next-line: react-hooks/exhaustive-deps
  }, [branch, appointmentType, serviceSubType, proficiency, meetingMethodCategory])

  return (
    <Formik
      initialValues={
        {
          advisor,
          appointmentType,
          branch: branch?.name ? branch : null,
          date,
          description: description || '',
          loanAmount,
          meetingMethod,
          serviceSubType,
          timeSlot
        } as DetailsFormValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid
            data-testid='booking-details-form'
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            {branchAlertNotification.show && (
              <Grid item className={classes.statusNotificationContainer}>
                <StatusNotification title={branchAlertNotification.title} message={branchAlertNotification.message} />
              </Grid>
            )}
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle littleTitle={littleTitle} bigTitle={bigTitle} />
              </Grid>
              <Grid item className={classes.formItemNoMargin}>
                <BranchSearchField
                  name='branch'
                  ref={branchSearchFieldRef}
                  options={branches}
                  label='Search branch name, city or street address'
                  onChange={(newValue: Branch | null) => {
                    setBranch(newValue)
                    setAdvisor()
                    setDate(null)
                  }}
                  validate={value => required(value, i18n.__(requiredText.branch))}
                  validateOnBlur
                />
              </Grid>
              <Grid item className={classes.formItem}>
                <AppointmentTypeField
                  name='appointmentType'
                  onClickOption={(value: string) => {
                    if (appointmentType !== value) {
                      setAppointmentType(value as AppointmentTypeEnum)
                      setServiceSubType(undefined)
                      setTimeSlot(undefined)
                      setDate(null)
                    }
                  }}
                  validate={value => required(value, i18n.__(requiredText.appointmentType))}
                  validateOnBlur
                />
              </Grid>
              {values.appointmentType && (
                <>
                  {values.appointmentType === AppointmentTypeEnum.PERSONAL && (
                    <Grid item className={classes.formItem} data-testid='appointment-info-box-personal-appointment'>
                      <DialogueDisplay
                        id='personal-appointment'
                        displayMessage={StringReplace(i18n.__(copy.Book.AdvisoryServiceTypeMessage), {
                          '%TIME_FRAME%': appointmentLength ? `${appointmentLength / 60} hr` : '1 hr'
                        })}
                      />
                    </Grid>
                  )}
                  {values.appointmentType === AppointmentTypeEnum.BUSINESS && (
                    <Grid item className={classes.formItem} data-testid='appointment-info-box-business-appointment'>
                      <DialogueDisplay
                        id='business-appointment'
                        displayMessage={StringReplace(i18n.__(copy.Book.AdvisoryServiceTypeMessage), {
                          '%TIME_FRAME%': appointmentLength ? `${appointmentLength / 60} hr` : '1-1.5 hr'
                        })}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    className={
                      values.serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT
                        ? classes.formItemNoMargin
                        : classes.formItem
                    }
                  >
                    <Typography
                      className={classes.labelText}
                      variant='body1'
                      data-testid='service-subtype-select-header'
                    >
                      What can we help you with?
                    </Typography>
                    <ServiceTypeSelectField
                      name='serviceSubType'
                      options={Options.ServiceSubType[values.appointmentType!]}
                      label='Select service type'
                      onChange={(event: React.ChangeEvent<{ value: AppointmentSubTypeEnum }>) => {
                        setServiceSubType(event.target.value)
                      }}
                      validate={value => required(value, i18n.__(requiredText.serviceSubType))}
                      validateOnBlur
                    />
                  </Grid>
                  {values.serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT && (
                    <Grid item className={classes.formItem}>
                      <LoanAmountField
                        name='loanAmount'
                        onClickOption={(value: string) => {
                          setLoanAmount(value as LoanAmountEnum)
                        }}
                        validate={value => required(value, i18n.__(requiredText.branch))}
                        validateOnBlur
                      />
                    </Grid>
                  )}
                  {values.serviceSubType &&
                    copy.DigitalOptions[values.serviceSubType] &&
                    copy.DigitalOptions[values.serviceSubType][values.appointmentType] && (
                      <Grid item className={classes.formItem} data-testid='digital-options-info-box'>
                        <DialogueDisplay
                          id='digital-options'
                          displayMessage={i18n.__(
                            copy.DigitalOptions[values.serviceSubType][values.appointmentType].Message
                          )}
                          dialogueFooter={
                            <Button
                              className={classes.learnMoreButton}
                              data-testid='learn-more-button'
                              href={i18n.__(copy.DigitalOptions[values.serviceSubType][values.appointmentType].Link)}
                              target='_blank'
                              onClick={handleLearnMoreButtonClick}
                              disableRipple
                            >
                              <div className={classes.learnMoreButtonText}>
                                {i18n.__(copy.DigitalOptions[values.serviceSubType][values.appointmentType].ButtonText)}
                              </div>
                            </Button>
                          }
                        />
                      </Grid>
                    )}
                </>
              )}
              {values.appointmentType && (
                <Grid item className={classes.formItem}>
                  <MeetingMethodField
                    name='meetingMethod'
                    onClickOption={(value: string) => {
                      setMeetingMethod(value as MeetingMethodEnum)
                    }}
                    infoMessage={
                      !bpId &&
                      meetingMethod &&
                      [MeetingMethodEnum.ONLINE, MeetingMethodEnum.PHONE].includes(meetingMethod)
                        ? i18n.__(copy.MeetingMethod.NewClientInBranchRequired)
                        : ''
                    }
                    validate={value => required(value, i18n.__(requiredText.meetingMethod))}
                    validateOnBlur
                  />
                </Grid>
              )}

              <>
                <Grid item className={classes.formItem}>
                  <TextInputField
                    name='description'
                    label={i18n.__(copy.Book.DescriptionInput.Label)}
                    placeHolderText={i18n.__(copy.Book.DescriptionInput.PlaceHolderText)}
                    onChange={(value: string) => {
                      setDescription(value)
                    }}
                    validate={value => required(value, i18n.__(requiredText.description)) || sensitiveInfo(value)}
                    validateOnBlur
                    ariaRequired
                  />
                </Grid>

                {availableDates?.length === 0 && (
                  <Grid item className={classes.formItem}>
                    <DialogueDisplay
                      id='no-dates'
                      displayMessage={
                        <>
                          {i18n.__(copy.Modify.NoAppointmentsMessage.BeforeLink)}
                          <Link
                            className={classes.inlineLink}
                            data-testid='no-dates-link'
                            onClick={handleNoAppointmentsClick}
                          >
                            {i18n.__(copy.Modify.NoAppointmentsMessage.LinkText)}
                          </Link>
                          {i18n.__(copy.Modify.NoAppointmentsMessage.AfterLink)}
                        </>
                      }
                    />
                  </Grid>
                )}

                {availableDates &&
                  availableDates.length > 0 &&
                  values.appointmentType &&
                  values.serviceSubType &&
                  values.meetingMethod &&
                  (advisors?.find(item => item.email === values.advisor?.email) || !values.advisor) && (
                    <Grid item className={classes.formItem}>
                      <AdvisorSelectionField
                        name='advisor'
                        options={advisors}
                        onChange={(event: React.ChangeEvent<{ value: string }>) => {
                          advisors.forEach(teamMember => {
                            if (teamMember.email === event.target.value) {
                              setAdvisor(teamMember)
                            }
                          })
                        }}
                      />
                    </Grid>
                  )}

                {availableDates && availableDates.length > 0 && (
                  <>
                    <Grid item className={classes.formItem}>
                      <DatePickerField
                        name='date'
                        minDate={minDate}
                        availableDates={availableDates!}
                        onChange={(value: Date | null) => {
                          setDate(value)
                        }}
                        validate={value => required(value, i18n.__(requiredText.date))}
                        validateOnBlur
                      />
                    </Grid>
                    {isFetching && evaluateDateTimeRendering && <CircularProgress style={{ marginLeft: '45%' }} />}
                    {evaluateDateTimeRendering && (
                      <>
                        {!isFetching && availableSlots && availableSlots.length > 0 && (
                          <Grid item className={classes.formItemNoMargin}>
                            <TimeSlotPickerField
                              name='timeSlot'
                              timeslots={availableSlots}
                              timezoneLabel={timezoneLabel}
                              onChange={(value: TimeSlot) => {
                                setTimeSlot(value)
                              }}
                              validate={value => required(value, i18n.__(requiredText.timeSlot))}
                              validateOnBlur
                            />
                          </Grid>
                        )}
                        {!isFetching && (!availableSlots || availableSlots.length === 0) && (
                          <Grid item className={classes.formItemNoMargin}>
                            <DialogueDisplay
                              id='no-slots'
                              displayMessage={
                                <>
                                  {StringReplace(i18n.__(copy.NoAvailableSlotsMessage.BeforeLink), {
                                    '%DATE%': shortDateString
                                  })}
                                  <Link href={i18n.__(copy.NoAvailableSlotsMessage.Link.Url)}>
                                    {i18n.__(copy.NoAvailableSlotsMessage.Link.Text)}
                                  </Link>
                                  {i18n.__(copy.NoAvailableSlotsMessage.AfterLink)}
                                </>
                              }
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item className={classes.formItem}>
                      {values.branch && values.appointmentType && values.serviceSubType && (
                        <Typography variant='body2' data-testid='appointment-length-notice'>
                          {StringReplace(
                            i18n.__(copy.AppointmentLengthLabel[meetingMethod || MeetingMethodEnum.BRANCH]),
                            {
                              '%LENGTH%': minutesToUserFacingTimeString(appointmentLength)
                            }
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
              </>
              {(availableDates === undefined || availableDates.length > 0) && (
                <Grid item className={classes.formItem}>
                  <ActionButton
                    style={{ marginTop: '16px' }}
                    buttonText={submitButtonText}
                    submitting={isSubmitting}
                    disabled={isSubmitting || !availableSlots || !availableDates}
                    type='submit'
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const BookingDetailsForm = observer(BaseBookingDetailsForm)
